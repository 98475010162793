<template>
    <div :class="'input-group input-mob-' + mobilityClass">
        <div class="input-group-prepend">
            <label class="input-group-text" :for="id">{{ title }}</label>
        </div>
        <input type="text" class="form-control" :id="id" placeholder="0" v-model.number="field">
    </div>
</template>

<script>
export default {
    name:     'AbcdeField',
    props:    ['id', 'title', 'mobilityClass'],
    computed: {
        field: {
            get() {
                let question = this.$store.getters.getQuestionById(this.id);
                if(typeof question !== 'undefined') {
                    return question.value;
                }

                return 0;
            },
            set(value) {
                if(value !== '') {
                    let splitted = this.id.split('.');
                    splitted[1]  = '1';
                    let firstRow = splitted.join('.');

                    if(firstRow !== this.id) {
                        let question = this.$store.getters.getQuestionById(firstRow);
                        if(typeof question === 'undefined' || value > question.value) {
                            value = question.value;
                        }
                    }

                    if(firstRow === this.id) {
                        splitted[1]++;
                        while(typeof this.$store.getters.getQuestionById(splitted.join('.')) !== 'undefined') {
                            let answer = this.$store.getters.getQuestionById(splitted.join('.'));
                            if(value < answer.value) {
                                this.$store.commit('setQuestion', {
                                    id:    splitted.join('.'),
                                    value: value
                                });
                                answer.value = value;
                            }
                            splitted[1]++;
                        }

                        let splittedIds = this.id.split('.');
                        splittedIds.pop();

                        let rows = [
                            ((splitted[2] !== 'a') ? this.$store.getters.getQuestionById(splittedIds.join('.') + '.a').value : value),
                            ((splitted[2] !== 'b') ? this.$store.getters.getQuestionById(splittedIds.join('.') + '.b').value : value),
                            ((splitted[2] !== 'c') ? this.$store.getters.getQuestionById(splittedIds.join('.') + '.c').value : value),
                            ((splitted[2] !== 'd') ? this.$store.getters.getQuestionById(splittedIds.join('.') + '.d').value : value),
                            ((splitted[2] !== 'e') ? this.$store.getters.getQuestionById(splittedIds.join('.') + '.e').value : value)
                        ];
                        let sum  = rows.reduce((a, b) => a + b, 0);

                        let difference = sum - this.$store.state.amountClients;
                        if(difference > 0) {
                            alert(this.$t('aantal-clienten-error'));
                            value = value - difference;
                            if(value <= 0) {
                                value = 0;
                            }
                        }
                    }

                    this.value = value;

                    this.$store.commit('setQuestion', {
                        id:    this.id,
                        value: value
                    });
                } else {
                    value      = 0;
                    this.value = value;

                    this.$store.commit('setQuestion', {
                        id:    this.id,
                        value: value
                    });
                }
            }
        }
    }
}
</script>
