<template>
    <div>
        <h2>6. {{ $t('stap-6-langere-titel') }}</h2>
        <p v-html="$t('stap-6-intro')"></p>
        <form class="mt-5">
            <div class="form-row">
                <div class="col-md-4 mb-3">
                    <label for="6.1">{{ $t('verrijdbare-actieve-tilliften') }}</label>
                    <NumberField id="6.1" />
                </div>
                <div class="col-md-4 mb-3">
                    <label for="6.2">{{ $t('actieve-plafondtilliften') }}
                        <span class="info-btn fa-stack fa-sm" data-toggle="modal" data-target="#actieveplafondliftenModal">
                            <i class="fas fa-circle fa-stack-2x color-primary"></i>
                            <i class="fas fa-info fa-stack-1x fa-inverse"></i>
                        </span>
                                     <!-- Modal -->
                        <div class="modal fade" id="actieveplafondliftenModal" tabindex="-1" role="dialog" aria-labelledby="actieveplafondliftenModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-md" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h3 class="modal-title" id="actieveplafondliftenLabel">{{ $t('actieve-plafondtillift') }}</h3>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <i class="fas fa-times"></i>
                                        </button>
                                    </div>
                                    <div class="modal-body text-center">
                                        <img class="img-fluid" src="/vvt/img/actieve-plafondtilliften.jpg">
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-primary" data-dismiss="modal">{{ $t('sluit') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </label>
                    <NumberField id="6.2" />
                </div>
                <div class="col-md-4 mb-3">
                    <label for="6.3">{{ $t('verrijdbare-passieve-tilliften') }}</label>
                    <NumberField id="6.3" />
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-4 mb-3">
                    <label for="6.4">{{ $t('passieve-plafondtilliften') }}</label>
                    <NumberField id="6.4" />
                </div>
                <div class="col-md-4 mb-3">
                    <label for="6.5">{{ $t('glijzeilen') }}</label>
                    <NumberField id="6.5" />
                </div>
                <div class="col-md-4 mb-3">
                    <label for="6.6">{{ $t('draaibedden') }}
                        <span class="info-btn fa-stack fa-sm" data-toggle="modal" data-target="#draaibeddenModal">
                            <i class="fas fa-circle fa-stack-2x color-primary"></i>
                            <i class="fas fa-info fa-stack-1x fa-inverse"></i>
                        </span>
                                     <!-- Modal -->
                        <div class="modal fade" id="draaibeddenModal" tabindex="-1" role="dialog" aria-labelledby="draaibeddenModalLabel" aria-hidden="true">
                            <div class="modal-dialog modal-md" role="document">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h3 class="modal-title" id="draaibeddenModalLabel">{{ $t('draaibed') }}</h3>
                                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                            <i class="fas fa-times"></i>
                                        </button>
                                    </div>
                                    <div class="modal-body text-center">
                                        <img class="img-fluid" src="/vvt/img/draaibed.jpg">
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-primary" data-dismiss="modal">{{ $t('sluit') }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </label>
                    <NumberField id="6.6" />
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-4 mb-3">
                    <label for="6.7">{{ $t('hoog-laag-douchestoelen') }}</label>
                    <NumberField id="6.7" />
                </div>
                <div class="col-md-4 mb-3">
                    <label for="6.8">{{ $t('hoog-laag-douchebrancards') }}</label>
                    <NumberField id="6.8" />
                </div>
                <div class="col-md-4 mb-3">
                    <label for="6.9">{{ $t('hoog-laag-baden') }}</label>
                    <NumberField id="6.9" />
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-4 mb-3">
                    <label for="6.10">{{ $t('elektrische-hoog-laag-bedden') }}</label>
                    <NumberField id="6.10" />
                </div>
            </div>
            <router-link :to="$t('resultaten-pagina')" class="btn btn-primary mt-5">{{ $t('bekijk-resultaten') }} <i class="fas fa-angle-right"></i></router-link>
        </form>
    </div>
</template>

<script>
import NumberField from "./components/NumberField";

export default {
    name:       'StepSix',
    components: { NumberField }
}
</script>
