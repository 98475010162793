<template>
    <div>
        <h2>2. {{ $t('stap-2-titel') }}</h2>
        <p v-html="$t('stap-2-intro')"></p>
        <form class="mt-5">
            <h4>{{ $t('aantal-clienten-per-mobiliteitsklasse') }}</h4>
            <div class="form-row mb-4">
                <div class="col-md-2 mb-3">
                    <AbcdeField id="2.1.a" title="A" mobilityClass="a" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="2.1.b" title="B" mobilityClass="b" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="2.1.c" title="C" mobilityClass="c" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="2.1.d" title="D" mobilityClass="d" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="2.1.e" title="E" mobilityClass="e" />
                </div>
            </div>
            <h4>{{ $t('aantal-clienten-bij-wie-een-stalift-wordt-gebruikt') }}</h4>
            <div class="form-row mb-4">
                <div class="col-md-2 mb-3">
                    <AbcdeField id="2.2.a" title="A" mobilityClass="a" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="2.2.b" title="B" mobilityClass="b" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="2.2.c" title="C" mobilityClass="c" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="2.2.d" title="D" mobilityClass="d" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="2.2.e" title="E" mobilityClass="e" />
                </div>
            </div>
            <h4>{{ $t('aantal-clienten-bij-wie-een-passieve-lift-wordt-gebruikt') }}</h4>
            <div class="form-row">
                <div class="col-md-2 mb-3">
                    <AbcdeField id="2.3.a" title="A" mobilityClass="a" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="2.3.b" title="B" mobilityClass="b" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="2.3.c" title="C" mobilityClass="c" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="2.3.d" title="D" mobilityClass="d" />
                </div>
                <div class="col-md-2 mb-3">
                    <AbcdeField id="2.3.e" title="E" mobilityClass="e" />
                </div>
            </div>
            <router-link :to="$t('stap-3-pagina')" class="btn btn-primary mt-5">{{ $t('volgende-stap') }} <i class="fas fa-angle-right"></i></router-link>
        </form>
        <span class="sub"><span
            class="color-primary">*</span> {{ $t('andere-effectieve-oplossingen') }}</span>
    </div>
</template>

<script>
import AbcdeField from "./components/AbcdeField";

export default {
    name:       'StepTwo',
    components: { AbcdeField }
}
</script>
