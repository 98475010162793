<template>
    <div>
        <h2>3. {{ $t('stap-3-titel') }}</h2>
        <p v-html="$t('stap-3-intro')"></p>
        <form class="mt-5">
            <h4>{{ $t('aantal-clienten-wat-steunkousen-draagt') }}</h4>
            <div class="form-row mb-4">
                <div class="col-md-2 mb-3">
                    <NumberField id="3.1" />
                </div>
            </div>
            <h4>{{ $t('aantal-clienten-bij-wie-een-goed-steunkoushulpmiddel-wordt-gebruikt') }}</h4>
            <div class="form-row">
                <div class="col-md-2 mb-3">
                    <NumberField id="3.2" />
                </div>
            </div>
            <router-link :to="$t('stap-4-pagina')" class="btn btn-primary mt-5">{{ $t('volgende-stap') }} <i class="fas fa-angle-right"></i></router-link>
        </form>
        <span class="sub"><span
            class="color-primary">*</span> {{ $t('andere-effectieve-oplossingen') }}</span>
    </div>
</template>

<script>
import NumberField from "./components/NumberField";

export default {
    name:       'StepThree',
    components: { NumberField }
}
</script>
