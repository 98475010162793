<template>
    <div>
        <section class="pt-0">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8" v-html="$t('copyright-content')"></div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Copyright'
}
</script>
