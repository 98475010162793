<template>
    <div class="footer">
        <!-- footer -->
        <section class="bg-secondary">
            <div class="container">
                <div class="row text-center mb-2">
                    <div class="col-md-12">
                        <img src="img/logo.svg" class="logo-footer img-fluid">
                        <div class="mb-4" v-if="this.$i18n.locale === 'nl'"></div>
                    </div>
                </div>
                <div class="row text-center mb-4" v-if="this.$i18n.locale !== 'nl'">
                    <div class="col-md-12">
                        <span class="sub">{{ $t('a+o-vvt') }}</span>
                    </div>
                </div>
                <div class="row text-center mb-4" v-if="this.$i18n.locale !== 'nl' && this.$i18n.locale !== 'se' && this.$i18n.locale !== 'en' && this.$i18n.locale !== 'de'">
                    <div class="col-md-12">
                        <span class="sub">Translation funded by eUlift<span v-if="this.$i18n.locale === 'fr'"> and VerV</span></span>
                    </div>
                </div>
                <div class="row text-center mb-4" v-if="this.$i18n.locale === 'de'">
                    <div class="col-md-12">
                        <span class="sub">Translation funded by Arjo</span>
                    </div>
                </div>
                <div class="row text-center">
                    <div class="col-lg-6 mx-auto">
                        <div class="row" v-if="this.$i18n.locale === 'nl'">
                            <div class="col-lg-2 col-md-3 col-4">
                                <img class="img-fluid footer-logo" src="/vvt/img/logo-actiz.jpg">
                            </div>
                            <div class="col-lg-2 col-md-3 col-4">
                                <img class="img-fluid footer-logo" src="/vvt/img/logo-cnv.jpg">
                            </div>
                            <div class="col-lg-2 col-md-3 col-4">
                                <img class="img-fluid footer-logo" src="/vvt/img/logo-debond.jpg">
                            </div>
                            <div class="col-lg-2 col-md-3 col-4">
                                <img class="img-fluid footer-logo" src="/vvt/img/logo-fbz.jpg">
                            </div>
                            <div class="col-lg-2 col-md-3 col-4">
                                <img class="img-fluid footer-logo" src="/vvt/img/logo-fnv.jpg">
                            </div>
                            <div class="col-lg-2 col-md-3 col-4">
                                <img class="img-fluid footer-logo" src="/vvt/img/logo-zorgthuis.jpg">
                            </div>
                        </div>
                        <div class="row flex-row justify-content-center" v-if="this.$i18n.locale === 'en'">
                            <div class="col-lg-3 col-md-4 col-5">
                                <img class="img-fluid footer-logo rounded" src="/vvt/img/logo-locomotion.svg">
                            </div>
                        </div>
                        <div class="row flex-row justify-content-center h-100" v-if="this.$i18n.locale !== 'nl' && this.$i18n.locale !== 'se' && this.$i18n.locale !== 'en'">
                            <div class="col-lg-3 col-md-4 col-5" v-if="this.$i18n.locale !== 'de'">
                                <div class="rounded bg-white rounded">
                                    <img class="img-fluid footer-logo" src="/vvt/img/logo-eulift.png">
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-5" v-if="this.$i18n.locale === 'fr'">
                                <div class="rounded bg-white rounded d-flex align-items-center h-100 p-2">
                                    <img class="img-fluid footer-logo rounded" src="/vvt/img/logo-verv.jpg">
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-4 col-5" v-if="this.$i18n.locale === 'de'">
                                <div class="rounded bg-white rounded d-flex align-items-center h-100 p-2">
                                    <img class="img-fluid footer-logo rounded" src="/vvt/img/logo-arjo.svg">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <footer class="overflow-visible ">
            <div class="container">
                <div class="row v-align-children">
                    <div class="col-lg-12 text-center" v-if="this.$i18n.locale === 'nl'">
                        <span class="sub-sub"><router-link to="/copyright">Copyright {{ year }} A+O VVT</router-link> | <router-link
                            :to="$t('privacy-pagina')">{{ $t('privacyverklaring') }}</router-link></span>
                    </div>
                    <div class="col-lg-12 text-center" v-if="this.$i18n.locale === 'en'">
                        <span class="sub-sub"><router-link to="/copyright">Copyright {{ year }} A+O VVT / LOCOmotion</router-link> | <router-link
                            :to="$t('privacy-pagina')">{{ $t('privacyverklaring') }}</router-link></span>
                    </div>
                    <div class="col-lg-12 text-center" v-if="this.$i18n.locale === 'se'">
                        <span class="sub-sub"><router-link to="/copyright">Copyright {{ year }} A+O VVT / LOCOmotion</router-link></span>
                    </div>
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            'year': (new Date).getFullYear()
        }
    }
}
</script>
