<template>
    <div>
        <h2>{{ $t('jouw-resultaten') }}</h2>
        <p class="mb-5" v-html="$t('resultaten-intro')"></p>

        <h4>{{ $t('basisgegevens') }}</h4>
        <table class="table col-md-8 col-12">
            <tbody>
            <tr>
                <td>{{ $t('naam-instelling') }}</td>
                <td>{{ organizationName }}</td>
            </tr>
            <tr>
                <td>{{ $t('afdeling') }}</td>
                <td>{{ departmentName }}</td>
            </tr>
            <tr>
                <td>{{ $t('totaal-aantal-clienten') }}</td>
                <td>{{ amountClients }}</td>
            </tr>
            <tr>
                <td>{{ $t('ingevuld-door') }}</td>
                <td>{{ contactName }}</td>
            </tr>
            <tr>
                <td>{{ $t('ingevuld-op') }}</td>
                <td>{{ currentDate }}</td>
            </tr>
            </tbody>
        </table>
        <h4 class="mt-5">{{ $t('resultaten-in-een-grafiek') }}</h4>
        <vue-frappe
            ref="resultsChart"
            id="results-chart"
            :labels="['1. ' + $t('bedden'), '1. ' + $t('glijzeilen'), '2. ' + $t('stalift'), '2. ' + $t('passieve-lift'), '3. ' + $t('steunkousen'), '4. ' + $t('hoog-laag-zitondersteuning'), '5. ' + $t('hoog-laag-ligondersteuning')]"
            type="bar"
            :height="400"
            :colors="['#BDDB4B', '#F05B29']"
            :dataSets="this.data"
            :barOptions="{stacked: 1, spaceRatio: 0.2}"
            :axisOptions="{xAxisMode: 'span', xIsSeries: 0}"
            :valuesOverPoints="true"
            :key="resultsChartKey">
        </vue-frappe>
        <div class="chart-legend-custom">
            <span class="legend-square-green"></span><span>{{ $t('volgens-de-praktijkrichtlijnen') }}</span><br>
            <span class="legend-square-red"></span><span>{{ $t('niet-volgens-de-praktijkrichtlijnen') }}</span>
        </div>
        <h4 class="mt-5">1. {{ $t('stap-1-resultaten-titel') }}</h4>
        <p>{{ $t('aantal-clienten-waarbij-een-elektrisch-hoog-laagbed-wordt-gebruikt') }}</p>
        <div class="form-row">
            <Result :payload="calculateAmounts(['1.2.c', '1.2.d', '1.2.e'], ['1.1.c', '1.1.d', '1.1.e'])"
                    :label-title="$t('aantal-clienten-in-c-d-en-e')" />
        </div>
        <p>{{ $t('aantal-clienten-waarbij-glijmateriaal-rolmateriaal-wordt-gebruikt') }}</p>
        <div class="form-row">
            <Result :payload="calculateAmounts(['1.3.c', '1.3.d', '1.3.e'], ['1.1.c', '1.1.d', '1.1.e'])"
                    :label-title="$t('aantal-clienten-in-c-d-en-e')" />
        </div>

        <h4 class="mt-5">2. {{ $t('stap-2-titel') }}</h4>
        <p>{{ $t('aantal-clienten-bij-wie-een-stalift-wordt-gebruikt') }}</p>
        <div class="form-row">
            <Result :payload="calculateAmounts(['2.2.c'], ['2.1.c'])" :label-title="$t('aantal-clienten-in-c')" />
        </div>
        <p>{{ $t('aantal-clienten-bij-wie-een-passieve-lift-wordt-gebruikt') }}</p>
        <div class="form-row">
            <Result :payload="calculateAmounts(['2.3.d', '2.3.e'], ['2.1.d', '2.1.e'])" :label-title="$t('aantal-clienten-in-d-en-e')" />
        </div>

        <h4 class="mt-5">3. {{ $t('stap-3-titel') }}</h4>
        <Result :label-title="$t('aantal-clienten')" :payload="calculateAmounts(['3.2'], ['3.1'])" />

        <h4 class="mt-5">4. {{ $t('stap-4-titel') }}</h4>
        <div class="form-row">
            <Result :label-title="$t('aantal-clienten')" :payload="calculateAmounts(['4.2'], ['4.1'])" />
        </div>

        <h4 class="mt-5">5. {{ $t('stap-5-titel') }}</h4>
        <div class="form-row">
            <Result :label-title="$t('aantal-clienten')" :payload="calculateAmounts(['5.2'], ['5.1'])" />
        </div>

        <h4 class="mt-5">6. {{ $t('stap-6-titel') }}</h4>
        <form>
            <div class="form-row">
                <div class="col-md-4 mb-3">
                    <label for="6.1">{{ $t('verrijdbare-actieve-tilliften') }}</label>
                    <input type="text" class="form-control mb-1" id="6.1" :value="getSumOf(['6.1'])" disabled>
                </div>
                <div class="col-md-4 mb-3">
                    <label for="6.2">{{ $t('actieve-plafondtilliften') }}</label>
                    <input type="text" class="form-control mb-1" id="6.2" :value="getSumOf(['6.2'])" disabled>
                </div>
                <div class="col-md-4 mb-3">
                    <label for="6.3">{{ $t('verrijdbare-passieve-tilliften') }}</label>
                    <input type="text" class="form-control mb-1" id="6.3" :value="getSumOf(['6.3'])" disabled>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-4 mb-3">
                    <label for="6.4">{{ $t('passieve-plafondtilliften') }}</label>
                    <input type="text" class="form-control mb-1" id="6.4" :value="getSumOf(['6.4'])" disabled>
                </div>
                <div class="col-md-4 mb-3">
                    <label for="6.5">{{ $t('glijzeilen') }}</label>
                    <input type="text" class="form-control mb-1" id="6.5" :value="getSumOf(['6.5'])" disabled>
                </div>
                <div class="col-md-4 mb-3">
                    <label for="6.6">{{ $t('draaibedden') }}</label>
                    <input type="text" class="form-control mb-1" id="6.6" :value="getSumOf(['6.6'])" disabled>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-4 mb-3">
                    <label for="6.7">{{ $t('hoog-laag-douchestoelen') }}</label>
                    <input type="text" class="form-control mb-1" id="6.7" :value="getSumOf(['6.7'])" disabled>
                </div>
                <div class="col-md-4 mb-3">
                    <label for="6.8">{{ $t('hoog-laag-douchebrancards') }}</label>
                    <input type="text" class="form-control mb-1" id="6.8" :value="getSumOf(['6.8'])" disabled>
                </div>
                <div class="col-md-4 mb-3">
                    <label for="6.9">{{ $t('hoog-laag-baden') }}</label>
                    <input type="text" class="form-control mb-1" id="6.9" :value="getSumOf(['6.9'])" disabled>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-4 mb-3">
                    <label for="6.10">{{ $t('elektrische-hoog-laag-bedden') }}</label>
                    <input type="text" class="form-control mb-1" id="6.10" :value="getSumOf(['6.10'])" disabled>
                </div>
            </div>
            <button @click="exportData" class="btn btn-primary mt-5 float-right">{{ $t('download-als-pdf') }} <i class="fas fa-file-pdf"></i></button>
        </form>
    </div>
</template>

<script>
import Result from './components/Result';
import Moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default {
    name:       'Results',
    components: {
        Result
    },
    computed:   {
        organizationName() {
            return this.$store.state.organizationName;
        },
        departmentName() {
            return this.$store.state.departmentName;
        },
        amountClients() {
            return this.$store.state.amountClients;
        },
        contactName() {
            return this.$store.state.contactName;
        },
        questions() {
            return this.$store.state.questions;
        }
    },
    data() {
        return {
            currentDate:     new Moment().format('D-M-Y'),
            data:            [{
                name:   this.$t('volgens-de-praktijkrichtlijnen'), chartType: 'bar',
                values: [0, 0, 0, 0, 0, 0, 0]
            }, {
                name:   this.$t('niet-volgens-de-praktijkrichtlijnen'), chartType: 'bar',
                values: [0, 0, 0, 0, 0, 0, 0]
            }],
            resultsChartKey: 0
        }
    },
    methods:    {
        updateChartData() {
            let correctArray   = [];
            let incorrectArray = [];
            this.$children.forEach(child => {
                if(child.$options.name === 'Result') {
                    if(typeof child.payload !== 'undefined') {
                        correctArray.push(child.payload.correct);
                        incorrectArray.push(child.payload.incorrect);
                    } else {
                        correctArray.push(0);
                        incorrectArray.push(0);
                    }
                }
            });

            this.data = [{
                name:   this.$t('volgens-de-praktijkrichtlijnen'), chartType: 'bar',
                values: correctArray
            }, {
                name:   this.$t('niet-volgens-de-praktijkrichtlijnen'), chartType: 'bar',
                values: incorrectArray
            }];

            this.forceRerender();
        },
        exportData(e) {
            e.preventDefault();

            html2canvas(document.body, {
                ignoreElements: function() {
                    document.querySelector('nav').style.display          = 'none';
                    document.querySelector('.logo-footer').style.display = 'none';
                    document.querySelector('noscript').style.display     = 'none';
                },
            }).then(canvas => {
                document.querySelector('nav').style.display          = 'flex';
                document.querySelector('.logo-footer').style.display = 'inline';
                document.querySelector('noscript').style.display     = 'inline';

                let width  = (document.body.scrollWidth * 72 / 96) * window.devicePixelRatio;
                let height = (document.body.scrollHeight * 72 / 96) * window.devicePixelRatio;

                let imgData = canvas.toDataURL('image/png');
                let doc     = new jsPDF('p', 'pt', [width, height], true);
                doc.addImage(imgData, 'PNG', 10, 10, null, null, null, 'MEDIUM');
                doc.save(this.$t('uw-resultaten-pdf'));
            });
        },
        forceRerender() {
            this.resultsChartKey += 1;
        },
        getSumOf(ids) {
            let result = 0;
            for(let index in ids) {
                let question = this.$store.getters.getQuestionById(ids[index]);
                if(typeof question !== 'undefined') {
                    result += parseInt(question.value);
                }
            }

            return result;
        },
        calculateAmounts(specific, totalSum) {
            let totalSumAmount = this.getSumOf(totalSum);
            let specificAmount = this.getSumOf(specific);

            return {
                sumAmount: parseInt(totalSumAmount),
                correct:   parseInt(specificAmount),
                incorrect: parseInt(totalSumAmount) - parseInt(specificAmount)
            }
        }
    },
    mounted() {
        this.updateChartData();
    }
}
</script>
