<template>
    <div>
        <h2>5. {{ $t('stap-5-titel') }}</h2>
        <p v-html="$t('stap-5-intro')"></p>
        <form class="mt-5">
            <h4>{{ $t('aantal-clienten-wat-liggend-gedoucht-gewassen-verzorgd-of-gebaad-wordt') }}</h4>
            <div class="form-row mb-4">
                <div class="col-md-2 mb-3">
                    <NumberField id="5.1" />
                </div>
            </div>
            <h4>{{ $t('aantal-clienten-bij-wie-hoog-laag-verstelbaar-ligmateriaal-wordt-gebruikt') }}</h4>
            <div class="form-row">
                <div class="col-md-2 mb-3">
                    <NumberField id="5.2" />
                </div>
            </div>
            <router-link :to="$t('stap-6-pagina')" class="btn btn-primary mt-5">{{ $t('volgende-stap') }} <i class="fas fa-angle-right"></i></router-link>
        </form>
        <span class="sub"><span
            class="color-primary">*</span> {{ $t('andere-effectieve-oplossingen') }}</span>
    </div>
</template>

<script>
import NumberField from "./components/NumberField";

export default {
    name:       'StepFive',
    components: { NumberField }
}
</script>
