<template>
    <div>
        <section class="pt-0">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8">
                        <h2>{{ $t('over-de-tilthermometer') }}</h2>
                        <div v-html="$t('over-de-tilthermometer-content')"></div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>
