<template :payload>
    <div class="form-row">
        <div class="col-md-4 mb-3">
            <label>{{ labelTitle }}</label>
            <input type="text" class="form-control mb-1" v-model="sumAmount" disabled>
        </div>
        <div class="col-md-4 mb-3">
            <div class="form-row">
                <label>{{ $t('volgens-de-praktijkrichtlijnen') }}</label>
                <div class="col-md-6">
                    <div class="input-group input-mob-a">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-check"></i></span>
                        </div>
                        <input type="text" class="form-control" v-model="correctAmount" disabled>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group input-mob-a">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-check"></i></span>
                        </div>
                        <input type="text" class="form-control" v-model="correctPercentage" disabled>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 mb-3">
            <div class="form-row">
                <label>{{ $t('niet-volgens-de-praktijkrichtlijnen') }}</label>
                <div class="col-md-6">
                    <div class="input-group input-mob-e">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-times"></i></span>
                        </div>
                        <input type="text" class="form-control" v-model="incorrectAmount" disabled>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="input-group input-mob-e">
                        <div class="input-group-prepend">
                            <span class="input-group-text"><i class="fas fa-times"></i></span>
                        </div>
                        <input type="text" class="form-control" v-model="incorrectPercentage" disabled>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name:  'Result',
    props: ['labelTitle', 'payload'],
    data() {
        return {
            sumAmount:           0,
            correctAmount:       0,
            correctPercentage:   '0%',
            incorrectAmount:     0,
            incorrectPercentage: '0%'
        }
    },
    mounted() {
        if(typeof this.payload !== 'undefined') {
            this.sumAmount = this.payload.sumAmount;
            if(this.sumAmount > 0) {
                this.correctAmount       = this.payload.correct;
                this.correctPercentage   = parseFloat(this.correctAmount * 100 / this.sumAmount).toFixed(2) + '%';
                this.incorrectAmount     = this.payload.incorrect;
                this.incorrectPercentage = parseFloat(this.incorrectAmount * 100 / this.sumAmount).toFixed(2) + '%';
            }
        }
    }
}
</script>