<template>
    <div>
        <h2>{{ $t('introductie') }}</h2>
        <p>{{ $t('introductie-tekst') }}</p>
        <p class="mb-5">{{ $t('twijfel-tekst') }}</p>
        <h3>{{ $t('vul-onderstaande-velden-in') }}</h3>
        <form>
            <div class="form-row">
                <div class="col-md-6 mb-3">
                    <label for="organizationName">{{ $t('naam-instelling') }}</label>
                    <input type="text" v-model="organizationName" class="form-control mb-1" id="organizationName"
                           :placeholder="$t('naam-instelling')">
                    <div class="valid-feedback">
                        {{ $t('dat-ziet-er-goed-uit') }}
                    </div>
                    <div class="invalid-feedback">
                        {{ $t('je-hebt-dit-veld-nog-niet-ingevuld') }}
                    </div>
                </div>
                <div class="col-md-6 mb-3">
                    <label for="departmentName">{{ $t('afdeling') }}</label>
                    <input type="text" v-model="departmentName" class="form-control mb-1" id="departmentName" :placeholder="$t('naam-van-de-afdeling')">
                    <div class="valid-feedback">
                        {{ $t('dat-ziet-er-goed-uit') }}
                    </div>
                    <div class="invalid-feedback">
                        {{ $t('je-hebt-dit-veld-nog-niet-ingevuld') }}
                    </div>
                </div>
            </div>
            <div class="form-row">
                <div class="col-md-6 mb-3">
                    <label for="amountClients">{{ $t('totaal-aantal-clienten') }}</label>
                    <input type="text" v-model="amountClients" @keyup="validateInteger" class="form-control mb-1" id="amountClients" :placeholder="$t('aantal-clienten')" required>
                    <div class="valid-feedback">
                        {{ $t('dat-ziet-er-goed-uit') }}
                    </div>
                    <div class="invalid-feedback">
                        {{ $t('je-hebt-dit-veld-nog-niet-ingevuld') }}
                    </div>
                </div>
                <div class="col-md-6 mb-3">
                    <label for="contactName">{{ $t('ingevuld-door') }}</label>
                    <input type="text" v-model="contactName" class="form-control mb-1" id="contactName" :placeholder="$t('vul-jouw-naam-in')">
                    <div class="valid-feedback">
                        {{ $t('dat-ziet-er-goed-uit') }}
                    </div>
                    <div class="invalid-feedback">
                        {{ $t('je-hebt-dit-veld-nog-niet-ingevuld') }}
                    </div>
                </div>
            </div>
            <button  class="btn btn-primary mt-2" @click.prevent="validateAndRedirect">{{ $t('volgende-stap') }} <i class="fas fa-angle-right"></i></button>
        </form>
    </div>
</template>

<script>
export default {
    name:     'Home',
    computed: {
        organizationName: {
            get() {
                return this.$store.state.organizationName;
            },
            set(value) {
                this.$store.commit('setOrganizationName', value);
            }
        },
        departmentName:   {
            get() {
                return this.$store.state.departmentName;
            },
            set(value) {
                this.$store.commit('setDepartmentName', value);
            }
        },
        amountClients:    {
            get() {
                return this.$store.state.amountClients;
            },
            set(value) {
                if(!isNaN(parseFloat(value)) && isFinite(value) && parseInt(value) !== 0) {
                    this.$store.commit('setAmountClients', value);
                }
            }
        },
        contactName:      {
            get() {
                return this.$store.state.contactName;
            },
            set(value) {
                this.$store.commit('setContactName', value);
            }
        }
    },
    methods:  {
        validateString(e) {
            if(e.key !== 'Tab') {
                if(e.target.value !== '') {
                    e.target.classList.remove('is-invalid');
                    e.target.classList.add('is-valid');
                } else {
                    e.target.classList.remove('is-valid');
                    e.target.classList.add('is-invalid');
                }

                this.$parent.showErrorBlock = false;
            }
        },
        validateInteger(e) {
            if(!isNaN(parseFloat(e.target.value)) && isFinite(e.target.value) && parseInt(e.target.value) !== 0) {
                if(e.key !== 'Tab') {
                    e.target.classList.remove('is-invalid');
                    e.target.classList.add('is-valid');
                }
            } else {
                e.target.value = 0;
                e.target.classList.remove('is-valid');
                e.target.classList.add('is-invalid');
            }
        },
        validateAndRedirect() {
            // Trigger keyup event
            document.getElementById('amountClients').dispatchEvent(new KeyboardEvent('keyup', {key: 'Tab'}));

            if(this.amountClients > 0) {
                this.$router.push(this.$t('stap-1-pagina'));
            }
        }
    }
}
</script>
